import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconReviewStar.module.css';
import IconCard from '../IconCard/IconCard';

const IconReviewStar = props => {
  const { className, rootClassName, isFilled, rating } = props;
  console.log('rating', rating)
  const filledOrDefault = isFilled ? css.filled : css.root;
  const classes = classNames(rootClassName || filledOrDefault, className);

  return rating ? (
    <div className={css.ratingBox}>
      <IconCard brand="filledstar" />
      <span className={css.ratingCount}>{rating}</span>
    </div>
  ) : (
    <IconCard brand="blankstar" />
  );
};

IconReviewStar.defaultProps = { className: null, rootClassName: null, isFilled: false };

const { bool, string } = PropTypes;

IconReviewStar.propTypes = {
  className: string,
  rootClassName: string,
  isFilled: bool,
};

export default IconReviewStar;
